@font-face {
  font-family: "Calibre-R";
  src: url("../fonts/Calibre-R/Calibre-R-Bold.eot");
  src: url("../fonts/Calibre-R/Calibre-R-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Calibre-R/Calibre-R-Bold.woff2") format("woff2"),
    url("../fonts/Calibre-R/Calibre-R-Bold.woff") format("woff"),
    url("../fonts/Calibre-R/Calibre-R-Bold.ttf") format("truetype"),
    url("../fonts/Calibre-R/Calibre-R-Bold.svg#Calibre-R-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Calibre-R";
  src: url("../fonts/Calibre-R/Calibre-R-Thin.eot");
  src: url("../fonts/Calibre-R/Calibre-R-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Calibre-R/Calibre-R-Thin.woff2") format("woff2"),
    url("../fonts/Calibre-R/Calibre-R-Thin.woff") format("woff"),
    url("../fonts/Calibre-R/Calibre-R-Thin.ttf") format("truetype"),
    url("../fonts/Calibre-R/Calibre-R-Thin.svg#Calibre-R-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Calibre-R";
  src: url("../fonts/Calibre-R/Calibre-R-Regular.eot");
  src: url("../fonts/Calibre-R/Calibre-R-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Calibre-R/Calibre-R-Regular.woff2") format("woff2"),
    url("../fonts/Calibre-R/Calibre-R-Regular.woff") format("woff"),
    url("../fonts/Calibre-R/Calibre-R-Regular.ttf") format("truetype"),
    url("../fonts/Calibre-R/Calibre-R-Regular.svg#Calibre-R-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Calibre-R";
  src: url("../fonts/Calibre-R/Calibre-R-Semibold.eot");
  src: url("../fonts/Calibre-R/Calibre-R-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Calibre-R/Calibre-R-Semibold.woff2") format("woff2"),
    url("../fonts/Calibre-R/Calibre-R-Semibold.woff") format("woff"),
    url("../fonts/Calibre-R/Calibre-R-Semibold.ttf") format("truetype"),
    url("../fonts/Calibre-R/Calibre-R-Semibold.svg#Calibre-R-Semibold")
      format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Calibre-R";
  src: url("../fonts/Calibre-R/Calibre-R-Medium.eot");
  src: url("../fonts/Calibre-R/Calibre-R-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Calibre-R/Calibre-R-Medium.woff2") format("woff2"),
    url("../fonts/Calibre-R/Calibre-R-Medium.woff") format("woff"),
    url("../fonts/Calibre-R/Calibre-R-Medium.ttf") format("truetype"),
    url("../fonts/Calibre-R/Calibre-R-Medium.svg#Calibre-R-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
}

html,
body {
  background-color: $color__main-grey;
  color: white;
  font-family: "Calibre-R", sans-serif;
  font-weight: normal;
  font-style: normal;
}

h1 {
  font-weight: 600;
}
