.btn {
  padding: 1em;
  border-radius: 3px;
  padding: 0.6em 0.25em;
  font-weight: 600;
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
  color: white;
}

.btn-round {
  border-radius: 30px;
}

.btn-primary {
  background-color: $color__btn-primary;
  border-color: $color__btn-primary;
  &:hover,
  &:active {
    background-color: $color__btn-primary-hover;
    border-color: $color__btn-primary-hover;
  }
}
.btn-accent {
  background-color: $color__btn-accent;
  border-color: $color__btn-accent;
  color: $color__brand-main;
}

.btn-cancel {
  background-color: #d8d8d8;
}

.btn-cta {
  border-radius: 25px;
  font-size: medium;
}
