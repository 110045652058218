div.login {
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  z-index: 10;
}

#login {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.intro-container {
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  .image-mask {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../img/login-intro-pic.jpg");
    background-position: 10% 50%;
    background-size: cover;
  }
}

.page {
  position: relative;
}

h1.logo {
  position: relative;
  font-size: 2.625rem;
  max-width: 200px;
  margin-bottom: 0;

  img {
    width: 100%;
  }
}

.login-form-container {
  height: 100vh;
}

.form-container,
.login-form-container {
  position: relative;
  max-width: 600px;
  padding: 0 15px;
  margin: 0 auto;

  form {
    position: relative;
    width: 100%;
  }

  .alert {
    width: 100%;
  }

  label {
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: 1em;

    input,
    textarea {
      font-size: 0.875rem;
    }

    input:focus,
    textarea:focus {
      outline: none;
    }

    textarea:focus ~ .label,
    textarea.valid ~ .label,
    textarea:valid ~ .label,
    input:not([value=""]) ~ .label,
    input:focus ~ .label,
    input.valid ~ .label {
      font-size: 0.625rem;
      top: 1.75em;
    }

    .label {
      position: absolute;
      top: 0;
      left: 1.25em;
      width: calc(100% - 3em);
      top: 50%;
      line-height: 1;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      color: #b1b9c8;
      pointer-events: none;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;

      &.textarea-label {
        top: 1.5em;
        transform: none;
      }
    }

    .validity {
      opacity: 0;
      width: 20px;
      height: 100%;
      background: url("../img/check-mark.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 1em;
    }

    input.valid:not(:focus) ~ .validity {
      opacity: 1;
    }
  }

  input:disabled,
  input.disabled,
  textarea:disabled,
  textarea.disabled {
    opacity: 0.5;
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="tel"],
  input[type="password"],
  textarea {
    background-color: $color__background-input;
    border: none;
    padding: 1.75em 1em 1.25em;
    border-radius: 3px;
    color: white;
    font-weight: 500;
    margin-bottom: 0;
    width: 100%;
    display: block;
    clear: both;
    box-shadow: none;
  }

  textarea {
    padding-top: 2.25em;
  }

  input[type="submit"],
  button {
    background: #fff;
    color: $color__background-input;
    padding: 0.6em 0.25em;
    font-weight: 500;
    border-radius: 3px;
    width: 100%;
    display: block;
    clear: both;
    border: inherit;

    & + button {
      margin-top: 1em;
    }
  }
  button.btn-cancel {
    background-color: #d8d8d8;
  }

  .row {
    width: auto;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background: $color__background-input !important;
    -webkit-box-shadow: 0 0 0 1000px $color__background-input inset !important;
    color: white !important;
    -webkit-text-fill-color: white !important;
    -webkit-appearance: none;
  }
}

form fieldset {
  width: 100%;
}

input[type="submit"] + a {
  margin-top: 1rem;
  text-align: center;
  display: block;
  color: $color__brand-main;
}

form .alert > p {
  margin-bottom: 0;
}

form .alert a {
  color: inherit;
  text-decoration: underline;
}
