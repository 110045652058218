.prompt {
  position: fixed;
  width: calc(100% - 30px);
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 13px;
  bottom: 0;
  padding: 2em 1.5em;
  z-index: 100;
  max-width: 600px;

  strong {
    font-weight: 600;
    color: #349dff;
  }

  h3 {
    font-size: 1rem;
    color: #1c1e22;
    font-weight: 500;
    margin: 0;
  }

  .prompt-icon > img {
    max-width: 40px;
    max-height: 40px;
    border-radius: 8px;
    margin-right: 20px;
  }

  .response-buttons {
    margin-top: 1em;
    & > button {
      border-radius: 4px;
      font-size: 0.9rem;
      background-color: #f1f3f5;
      color: #8897ae;
      padding: 0.5em 2em;
      text-align: center;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin-right: 10px;
      font-weight: normal;

      &:last-of-type {
        margin-right: 0 !important;
      }

      &.yes {
        color: #349dff;
        background: #e2f1ff;
      }
    }
  }

  .close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 15px;
    height: 15px;
    background-image: url("../../img/close-icon.svg");
    background-repeat: no-repeat;
    background-size: 15px;
    z-index: 10;
  }

  &.instructions {
    &:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #fff;
      border-width: 15px;
      margin-left: -15px;
    }

    img.add-home-icon {
      max-width: 40px;
    }

    .step-2 {
      margin-top: 1em;
    }

    .prompt-icon > img {
      width: 30px;
      height: 30px;
    }
  }
}
