.room-control-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;

  .fade-enter-active & {
    position: relative;
  }

  .select-subsystem,
  .navigtation-header {
    animation: fadeIn 0.5s;
  }

  .playlist,
  .browse-list {
    padding-bottom: 150px;
  }

  .header-upper > div {
    animation: none;
  }

  .kohost-room-control-module {
    position: relative;
    overflow: visible;

    &::-webkit-scrollbar {
      display: none;
    }

    .width-container::-webkit-scrollbar,
    .scroll-check::-webkit-scrollbar {
      display: none;
    }

    .now-playing {
      position: fixed;
    }

    .mini-now-playing {
      position: fixed;
    }

    .no-rooms-wrapper,
    .select-a-source,
    .source-message {
      position: fixed;
    }

    .scroll-check {
      position: relative;
      overflow: scroll;
    }

    .climate-control .nav-header {
      background: transparent;

      .title {
        opacity: 0;
      }
    }

    .climate-control .scroll-check {
      position: fixed;
      overflow: hidden;
    }

    .nav-header {
      background: #1d1f22;
    }

    .tv-controls .volume {
      margin-bottom: 0;
    }

    .tv-controls .tv-dvr-buttons {
      padding: 15px 0;
    }

    @media (max-width: 414px) {
      .dvr-buttons {
        button {
          margin-left: 0.25em !important;
          margin-right: 0.25em !important;
        }
      }
    }

    @media (max-width: 414px) and (display-mode: browser) {
      .dvr-buttons {
        margin-top: 0;
      }
    }

    @media (max-width: 375px) and (display-mode: browser) {
      // .swipe-controller {
      //   margin-left: 35px;
      //   margin-right: 35px;
      //   width: calc(100% - 70px);
      // }

      .dvr-buttons {
        button {
          margin-left: 0.25em !important;
          margin-right: 0.25em !important;
        }
      }
    }

    @media (max-width: 414px) and (display-mode: browser) {
      .tv-buttons {
        margin-top: 0;
      }

      .tv-controls .section-header {
        margin-bottom: 0.5em;
      }
    }

    @media (max-width: 375px) {
      // .swipe-controller {
      //   margin-left: 35px;
      //   margin-right: 35px;
      //   width: calc(100% - 70px);
      // }

      .dvr-buttons {
        button {
          margin-left: 0.25em !important;
          margin-right: 0.25em !important;
        }
      }
    }

    #keypad {
      position: fixed;
      bottom: 83px;
    }

    .control-footer {
      position: fixed !important;
      top: auto !important;
      bottom: 0 !important;
    }

    .lights-control .scroll-check {
      overflow: visible;
    }

    .lights-control .scroll-check .nav-header {
      position: sticky;
      top: 0;
      left: auto;
      right: auto;
      bottom: auto;
    }

    .volume input.range-slider {
      margin: 0 1em;
    }

    .volume input.range-slider::-webkit-slider-runnable-track {
      background-color: #fff;
    }
  }

  &.room-lock-wrapper {
    position: fixed;
    width: 100%;
    bottom: 0;
    top: auto;
    right: 0;
    width: 100%;
    z-index: 50;
    height: auto;

    .kohost-room-control-module {
      position: static;
    }
  }
}
