$color__main-grey: #1d1f22;
$color__main-light-grey: #c7c7c7;
$color__background-input: #24262c;
$color__background-light-block: #222429;
$color__brand-main: #e5476c;
$color__brand-gold: #bea770;
$color__brand-cyan: #9aebff;
$color__background-element: #424858;
$color__border-device-select: #4c515b;
$color__background-device-state: #373b45;

$color__background-climate-state-heating: #e5476c;
$color__background-climate-state-cooling: #385fc3;
$color__background-climate-state-default: #373b45;

// Device color schemes
$color__device-lights-main: #ffb000;
$color__device-climate-main: #5cb200;
$color__device-shades-main: #568efb;
$color__device-tv-main: #ac63b7;
$color__device-music-main: #e5476c;

// Control Footer
$color__background-control-footer: #222429;

// Button Colors
$color__btn-primary: #28a745;
$color__btn-primary-hover: #267137;

$color__btn-accent: #373639;

$color__roomlock-state-locked: #6a7798;
$color__roomlock-state-unlocked: #44b078;
