.cta-button-container {
  position: fixed;
  z-index: 100;
  bottom: 30px;
  right: 30px;

  .btn {
    -webkit-box-shadow: 10px 10px 40px 0px rgba(0,0,0,1);
    -moz-box-shadow: 10px 10px 40px 0px rgba(0,0,0,1);
    box-shadow: 10px 10px 40px 0px rgba(0,0,0,1);
    font-weight: 400;
    display: inline-flex;
    width: 88px;
    height: 52px;
    transition: width 0.5s;
    padding: 1em;
    .text {
      display: block;
      opacity: 1;
      transition: opacity 1s;
      line-height: 18px;
      padding-left: 15%;
      margin-bottom: 7px;
    &:hover {
        color: $color__brand-main;
      }
    }
  }

  .btn::before {
    background-image: url("../../img/pencil-accent.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 18px;
    content: "";
    min-width: 18px;
    min-height: 18px;
    display: block;
    opacity: 1;
  }

  .active {
    background-position: 10px;
    width: 52px;
    color: $color__brand-main;
    text-decoration: none;
    .text {
      opacity: 0;
      transition: opacity .3s;
    }
  } 

}
