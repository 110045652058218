$menu--item-background-color: #2d3036;
$menu--item-color-green: #44b078;

.cart {
  position: relative;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;

  .nav-header & {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .cart-icon {
    position: relative;
  }
  .count {
    position: absolute;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    background-color: $color__brand-main;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    right: -10px;
    top: -6px;
  }
}

.menu-search {
  width: 100%;
  background-color: $menu--item-background-color;
  position: fixed;
  z-index: 201;
  min-height: 116px;
  top: 0;
  left: 0;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  input,
  input:focus {
    background-color: transparent;
    outline: none;
    color: white;
    border: none;
    box-shadow: none;
  }
  .input-group-text {
    background-color: $menu--item-background-color;
    border: none;
    svg {
      max-height: 20px;
      width: auto;
    }
  }

  button.close-search {
    background-color: #41454f;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    align-self: center;
    appearance: none;
    height: auto;
    line-height: 1;
    max-height: 30px;
    span {
      padding: 8px 6px;
      font-size: 0.85rem;
      line-height: 100%;
    }
  }
}

.menu-nav {
  padding-left: 0;
  padding-right: 0;

  .feature-block:nth-child(even) {
    padding-left: 0;
  }

  .feature-block:nth-child(odd) {
    padding-left: 15px;
  }
  .feature-block h2 {
    text-shadow: 1px 1px #000;
  }
}

.menu-items-group {
  position: relative;
  z-index: 10;
  margin-bottom: 30px;
}

.menu-image--header {
  position: absolute;
  padding: 1em 15px;
  width: 100%;
  background-image: linear-gradient(
    180deg,
    #1c1e22 0%,
    rgba(28, 30, 34, 0) 100%
  );
}
.menu-items--group-name {
  font-size: 1.25rem;
  margin-bottom: 1em;
}
.menu-item--preview {
  background-color: $menu--item-background-color;
  margin-bottom: 1em;

  .menu-item--name {
    position: relative;
    align-items: center;
  }

  .menu-item--count {
    width: 25px;
    height: 25px;
    margin-left: 8px;
    background-color: $color__brand-main;
    color: white;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
  }

  .menu-item--price {
    color: white;
    font-size: 0.875rem;
  }

  .menu-item--image {
    flex: 2 1 auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: auto;
    position: relative;
    max-width: 35%;

    .checkout & {
      max-width: 25%;
    }

    &:after {
      display: block;
      padding-bottom: 100%;
      content: "";
    }
  }
  .menu-item--info {
    flex: 3;
    padding: 15px 10px 15px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h5 {
      font-weight: normal;
      font-size: 1.1rem;
    }
  }

  .menu-item--description {
    font-size: 0.9125rem;
    color: #b5b5b5;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
    margin-bottom: 10px;
  }
  .menu-item--controls {
    & > * {
      margin: 0 3px;
    }
    button {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 0;
      font-size: 1rem;
      cursor: pointer;
      background-color: #c1c1c1;
    }
  }
}

.full-menu-item {
  position: fixed;
  min-height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  z-index: 300;
  background: $color__main-grey;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

.order-menu > .menu-items-group:last-child {
  min-height: 90vh;
}

form.menu-item--option-form,
ul.cart-items {
  margin-bottom: 200px;
}

.menu-item--full {
  padding: 0;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  .menu-item--content {
    padding: 15px;
  }
  .menu-item--image {
    padding-bottom: 100%;
    max-height: 50vh;
    margin-bottom: 1em;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .menu-item--name {
    font-size: 1.875rem;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .menu-item--price {
    font-size: 1.5rem;
    margin-bottom: 10px;
    display: block;
  }

  .menu-item--description {
    font-size: 1rem;
  }
}

.menu-item--option-form label {
  font-size: 0.9rem;
}

.option-form-row {
  margin-bottom: 1.5em;
}

.menu-item-form--select {
  background: $menu--item-background-color;
  color: white;
  display: block;
  width: 100%;
  border: none;
  padding: 20px 10px;
  border-radius: 3px;
  appearance: none;
  position: relative;
}

.menu-item-form--select-wrapper {
  position: relative;
  select {
    position: relative;
  }
  &:after {
    content: "";
    background-image: url("../img/menu-select-down-arrow.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 1em;
    z-index: 2;
  }
}

.item-notes.option-form-row {
  padding-top: 1.5em;
  border-top: 1px solid #2d3036;

  .checkout & {
    padding-top: 0;
    border: none;
  }
}

.menu-item-form--item-notes {
  width: 100%;
  background-color: $menu--item-background-color;
  background-image: url("../img/edit-pencil.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: calc(100% - 10px) 15px;
  color: white;
  padding: 15px 10px;
  border: none;

  &:not(:placeholder-shown) {
    background-image: none;
  }

  &::placeholder {
    color: #b1b9c8;
  }
}

.menu-item-form--qty {
  display: flex;
  align-items: stretch;

  button,
  input {
    color: white;
    background: $menu--item-background-color;
    text-align: center;
    border: none;
    font-size: 1.375rem;
    width: 50px;

    &:active,
    &:focus,
    &:hover {
      color: white;
      outline: none;
      box-shadow: none;
    }
  }

  button.minus:before {
    content: "-";
  }

  button.plus:before {
    content: "+";
  }
}

.full-menu-item .nav-header {
  .header-upper {
    background: transparent;
    background-image: linear-gradient(
      180deg,
      rgba(28, 30, 34, 0.75) 0%,
      rgba(28, 30, 34, 0) 80%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .header-lower {
    display: none !important;
  }
}

.dining-section > h2 {
  padding-left: 15px;
}
.dining-content-wrapper {
  .component-select-item h3 {
    font-size: 1.375rem;
  }
}

.dining-header {
  display: flex;
  height: 66px;
}

.checkout {
  position: fixed;
  min-height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  z-index: 300;
  padding: 15px;
  background: $color__main-grey;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0 !important;
  }

  &.checkout-success {
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
  }
}

.btn-checkout {
  width: 100%;
  text-align: center;
}
ul.cart-items {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}

.order-notes-options {
  margin-bottom: 1.5em;
  border-bottom: 1px solid #2d3036;
}

.totals {
  td {
    padding: 0.5em;
  }
  strong {
    font-weight: 600;
  }
}

.order-success {
  background-color: $menu--item-color-green;
  color: white;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  &::-webkit-scrollbar {
    width: 0 !important;
  }

  .order-content {
    padding: 15px;
  }
  .success-check {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2em auto 0.5em;

    svg {
      width: 70%;
      height: auto;
    }
  }
  .done-order-button {
    position: absolute;
    bottom: 30px;
  }
  .main-shop-button {
    background: none;
    border: 1px solid #fff;
  }
}

.menu-category-nav {
  position: fixed;
  top: 65px;
  width: 100%;
  z-index: 99;
  background-color: $color__main-grey;
  padding: 0 15px 15px 15px;
  height: 50px;
  overflow-x: scroll;
  overflow-y: hidden;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.52);

  @include make-container();
  @include make-container-max-widths();
  left: 50%;
  transform: translateX(-50%);

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    width: auto;
    white-space: nowrap;

    li {
      margin: 0 10px 0 0;
      display: inline-block;
      height: auto;

      a {
        padding: 5px 10px;
        color: white;
        font-size: 1.125rem;
        border-radius: 4px;
        display: block;
        line-height: 1;
        &:hover,
        &:focus {
          text-decoration: none;
        }
        &.active {
          text-decoration: none;
          background-color: #394254;
        }
      }
    }
  }
}

.main-shop-button,
button.main-shop-button {
  background-color: $menu--item-color-green;
  color: white;
  display: block;
  width: 100%;
  border: none;
  border-radius: 3px;
  padding: 20px 10px;
  font-weight: 500;
}

.menu-item--qty {
  padding: 3px;
  background-color: $menu--item-color-green;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 10px;
  margin-left: 1em;
  font-size: 1em;
  line-height: 1;

  &.cart-count {
    background-color: white;
    color: $menu--item-color-green;
  }
}

.main-shop-button-wrapper {
  position: fixed;
  padding: 0 15px;
  bottom: 30px;
  width: 100%;
  z-index: 50;
  left: 0;
}

.totals {
  width: 100%;
  margin-bottom: 2em;

  td.price {
    text-align: right;
  }

  .tax-row {
    td {
      padding-bottom: 15px;
    }
  }

  .grand-total-row {
    border-top: 1px solid #2d3036;

    .order-success & {
      border-color: #5ccc92;
    }

    td {
      padding-top: 15px;
      font-size: 1.2rem;
    }
  }
}

ul.ordered-menu-items {
  list-style: none;
  padding-left: 0;

  li {
    margin-bottom: 1em;
    font-size: 1.05em;
  }

  .menu-item--qty {
    color: $menu--item-color-green;
    background-color: #fff;
    margin-left: 0;
    width: 25px;
    height: 25px;
  }
}

.checkout-content {
  position: relative;
  z-index: 1;
}

.time-selection {
  width: auto;
  margin: 0 auto 2.5em;
  text-align: center;
  p {
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    background-color: #2a905b;
    font-size: 0.9em;
    padding: 4px 11px;
    border-radius: 15px;
  }
}

@keyframes MenuImageIn {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes MenuContentIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes MenuContentOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes FadeComponentOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(75%);
  }
}

@keyframes OrderSuccessIn {
  from {
    transform: translateY(150%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes DropIn {
  from {
    transform: translateY(-300%);
  }

  to {
    transform: translateY(0);
  }
}

.menu-search-enter-active {
  animation-name: DropIn;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
}

.menu-search-exit-active {
  animation-name: DropIn;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  animation-direction: reverse;
}

.checkout-enter-active {
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  .checkout-content {
    opacity: 0;
    animation-name: MenuContentIn;
    animation-delay: 200ms;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
  }
}

.checkout-exit-active {
  animation-name: FadeComponentOut;
  animation-duration: 500ms;
  animation-fill-mode: forwards;

  .main-shop-button-wrapper {
    opacity: 0;
    animation-name: FadeComponentOut;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
  }
}

.full-menu-item-enter-active {
  animation-name: fadeIn;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  .menu-item--image {
    transform-origin: bottom left;
    transform-style: flat;
    animation: MenuImageIn 500ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  .menu-item--content {
    opacity: 0;
    animation-delay: 300ms;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
    animation-name: MenuContentIn;
  }
}

.full-menu-item-exit-active {
  animation-name: FadeComponentOut;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
}

.order-success-enter-active {
  animation-name: OrderSuccessIn;
  animation-duration: 500ms;
  animation-fill-mode: forwards;

  .success-order-content,
  .done-order-button {
    opacity: 0;
  }

  .success-check svg {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes ScaleUp {
  0% {
    transform: scale(0);
  }

  62% {
    transform: scale(0);
  }

  81% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

.order-success-enter-done {
  .success-order-content,
  .done-order-button {
    animation-fill-mode: forwards;
    animation: MenuContentIn 500ms;
  }
  .success-check svg {
    animation: ScaleUp 800ms ease;
    animation-fill-mode: forwards;
  }
}

.order-success-exit-active {
  animation-fill-mode: forwards;
  animation: MenuContentOut 500ms;

  .success-check svg {
    transform: scale(1);
  }
}

.spinner {
  margin-right: 0.25rem;
  top: -1px;
  position: relative;
}
