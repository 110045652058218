.concierge-wrapper {
  overflow-x: hidden;
  animation: fadeIn 0.5s;
}

.new-message {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 301;

  .btn-new-message {
    background-image: linear-gradient(
      315deg,
      rgba(132, 81, 166, 1) 0%,
      rgba(73, 118, 172, 1) 65%,
      rgba(40, 50, 83, 1) 100%
    );
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.5);
    border: 0;
    border-radius: 100%;
    width: 80px;
    height: 80px;
    margin: 22px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      outline: none;
    }
  }
}

.btn-quick-service {
  padding: 0;
  margin: 0;
  background: none;
  box-shadow: none;
  border: none;

  &:hover {
    p {
      text-decoration: none;
    }
  }
}

.notification-history {
  padding-left: 15px;
  padding-right: 15px;

  .btn-view-more {
    background: #1c1e22;
    border: 1px solid #424858;
    border-radius: 6px;
    width: 100%;
    color: white;
    margin-bottom: 1em;
  }
}

.notification--item {
  padding: 15px;
  background: #2e3036;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.38);
  border-radius: 6px;
  margin-bottom: 1em;
}

.notification--title {
  font-size: 18px;
}

.notification--date,
.notification--subtitle {
  margin-bottom: 0;
  font-size: 14px;
  color: #c7c7c7;
}

.quick-services {
  padding-left: 15px;
  position: fixed;
  bottom: 0;
}

.quick-service {
  border-radius: 6px;
  margin-bottom: 1em;
  margin-right: 1em;
  min-width: 150px;
  flex: 1 0 0;

  img {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background: #415082;
    background-image: linear-gradient(#415082, #2d385c);
  }
}

.quick-services--items {
  display: flex;
  flex-direction: row;
}

.quick-service--image {
  width: 100%;
}

.quick-service--title {
  background-color: #2e3036;
  font-weight: 600;
  color: white;
  margin: 0;
  padding: 1em 1em 3em;
  text-align: left;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.fullscreen-notification,
.send-message-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1d1f22;
  z-index: 300;

  &.slide-enter-active {
    animation: slideFromLeft 350ms;
    animation-fill-mode: forwards;
  }

  &.slide-exit-active {
    animation: slideFromLeft 350ms reverse;
    animation-fill-mode: forwards;
  }
}

.notifications {
  height: 40vh;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-bottom: 2em;
}

.btn-edit-user-response {
  padding: 3px 8px;
  color: white;
  font-weight: normal;
  border: 1px solid rgba(108, 117, 125, 0.16);
  span {
    display: flex;
  }
}

.fullscreen-notification--response-buttons {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    flex: 1;
    background-color: #1c1e22;
    @extend .btn-view-more;
    @include font-size(0.9rem);
    margin-right: 0.5em;

    &:active,
    &.active {
      background-color: #44b078;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.fullscreen-notification--url {
  color: $color__brand-main;

  &:hover {
    color: $color__brand-main;
    text-decoration: underline;
  }
}

.btn-cancel-edit-user-response {
  flex: 1;
  background-color: #1c1e22;
  @extend .btn-view-more;
  @include font-size(0.9rem);
  margin-right: 0.5em;
}

.send-message-page {
  z-index: 302;
}

@keyframes slideFromLeft {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideFromBottom {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes puffOut {
  0% {
    opacity: 0;
    transform-origin: bottom right;
    transform: translateY(100%) scale(0.15);
    -webkit-filter: blur(8px);
    filter: blur(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
    -webkit-filter: blur(0);
    filter: blur(0);
  }
}

.message-sent-success-active {
  animation-name: slideFromBottom;
  animation-direction: reverse;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
}

.fullscreen-notification--content {
  padding: 3em 1.5em;
}

.fullscreen-notification--title-container {
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}

.fullscreen-notification--image {
  max-width: 100%;
  margin-bottom: 1em;
}

button.message-sent-done-button {
  width: calc(100% - 30px);
  left: 15px;
}

.send-message-page-enter-active {
  animation: puffOut 350ms;
  animation-fill-mode: forwards;
}

.send-message-page-exit-active {
  animation: slideFromBottom 350ms reverse;
  animation-fill-mode: forwards;
}
