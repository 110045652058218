$header__height: 90px;
$header__top-margin: 15px;
$header__bottom-margin: 15px;

@mixin gradientTile($image) {
  background: #415082;
  background: url($image);
  background: url($image), #2e3036;
  // background-image: url($image),
  //   linear-gradient(
  //     315deg,
  //     rgba(132, 81, 166, 1) 0%,
  //     rgba(73, 118, 172, 1) 65%,
  //     rgba(40, 50, 83, 1) 100%
  //   );

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

header.main-header {
  margin: 0 auto;
  height: $header__height;
  padding: $header__top-margin;
  box-sizing: content-box;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: calc(100% - 30px);
  background: $color__main-grey;

  * {
    transition: all 0.2s ease-in-out;
  }

  p {
    color: $color__main-light-grey;
    margin-bottom: 0;
  }

  .property-info {
    height: 100%;
    position: relative;
    flex: 1;

    p {
      line-height: 1;
    }

    h1 {
      width: 100%;
      line-height: 1;
    }
  }
}

.app-nav {
  margin-top: $header__height + $header__top-margin + $header__top-margin;
  background-color: $color__main-grey;
  z-index: 2;
  position: relative;

  .order-menu & {
    margin-top: 0;
  }
}

.greeting {
  font-size: 1.6rem;
  font-weight: 500;
}

.user-profile-wrap img {
  max-width: 50px;
  border-radius: 50%;
}

.feature-content-wrap {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  display: block;

  @include media-breakpoint-down("sm") {
    background-position: top left;
  }

  .main-nav-item & {
    height: 25vh;
    min-height: 195px;
    max-height: 350px;
    @include media-breakpoint-up("md") {
      max-height: 200px;
    }
  }

  .concierge & {
    @include gradientTile("../img/reception-3d.png");
  }
  .casino & {
    background-image: url("../img/casino.jpg");
  }
  .dining & {
    @include gradientTile("../img/food-3d.png");
  }

  .entertainment & {
    background-image: url("../img/entertainment.jpg");
  }

  .pool & {
    background-image: url("../img/pool.jpg");
  }

  .spa & {
    background-image: url("../img/spa.jpg");
  }

  .parking & {
    background-image: url("../img/parking.jpg");
  }

  .map & {
    background-image: url("../img/map.jpg");
  }

  .room-control & {
    @include gradientTile("../img/hotelroom-3d.png");
  }

  .building-control & {
    @include gradientTile("../img/office-3d.png");
    // @include gradientTile("../img/gradient/1.png");
  }

  .building-control-dcslv & {
    @include gradientTile("../img/classroom-3d.png");
  }

  .cameras & {
    @include gradientTile("../img/camera-3d.png");
    // @include gradientTile("../img/gradient/4.png");
  }

  .security & {
    @include gradientTile("../img/alarm-3d-blank.png");
    // @include gradientTile("../img/gradient/2.png");
  }

  .valet & {
    @include gradientTile("../img/valet-icon.png");
  }

  .rentals & {
    @include gradientTile("../img/rentals-icon.png");
  }

  .store & {
    @include gradientTile("../img/store-icon.png");
  }

  .experiences & {
    @include gradientTile("../img/experiences-icon.png");
  }

  /* Online Menu */

  .desserts & {
    background-image: url("../img/menu/desserts.jpg");
  }

  .appetizers & {
    background-image: url("../img/menu/appetizers.jpg");
  }

  .entrees & {
    background-image: url("../img/menu/entrees.jpg");
  }

  .salads & {
    background-image: url("../img/menu/salads.jpg");
  }

  .beverages & {
    background-image: url("../img/menu/beverages.jpg");
  }

  .sides & {
    background-image: url("../img/menu/sides.jpg");
  }
}

.app-nav .feature-block::after {
  content: "";
  display: block;
  padding-bottom: 100%;
  pointer-events: none;
}

.feature-block {
  margin-bottom: 30px;
  position: relative;

  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
  }

  &.col-12:after {
    content: none;
    padding-bottom: 0 !important;
  }

  .feature-title-wrap {
    position: absolute;
    left: 10px;
    bottom: 10px;
  }

  h2 {
    font-size: 1.125rem;
    color: white;
    margin: 0;
    font-weight: 500;
  }

  &.main-nav-item {
    &:after {
      content: none;
    }
  }

  &:nth-child(odd):not(.main-nav-item) {
    @media screen and (max-width: 768px) {
      padding-left: 7px;
    }
  }

  &:nth-child(even):not(.main-nav-item) {
    @media screen and (max-width: 768px) {
      padding-right: 8px;
    }
  }
}

.powered-by {
  font-size: 0.875rem;
  color: #c7c7c7;
  padding: 1.5em 20px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  p {
    margin-bottom: 0;
  }

  span {
    color: $color__brand-main;
  }
}
