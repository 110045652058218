.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-fullscreen {
  @extend .container;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1f2327;
}

.spinner {
  color: #e5476c;
  width: 3rem;
  height: 3rem;
}
