.control-block {
  width: 100%;
  padding: 1.25em 1em;
  background: #222429;
  border-radius: 6px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.38);
  margin-bottom: 1em;

  &:last-of-type {
    margin-bottom: 150px;
  }
}
 
.width-md .control-block {
  flex: 48%;
  max-width: 48%;

  &:last-of-type {
    margin-bottom: 1em;
  }
}

.width-md .control-block:nth-child(odd) {
  margin-right: 2%;
}

.control-block--header {
  margin-bottom: 1em;
}

.control-block--title {
  font-size: 1rem;
  margin-bottom: 0;
  font-weight: 500;
}
