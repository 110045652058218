@import "control-header";
@import "blur";
@import "prompt";
@import "cta";
@import "buttons";
@import "room-control";
@import "component-select";
@import "control-block";
@import "toggle-switch";
@import "settings";

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.control-wrapper {
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
  margin-left: auto;
  margin-right: auto;

  &.hide {
    display: none !important;
  }
}

.not-found-text {
  margin-top: 0;
}
