.setting-select-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  @include make-container();
  @include make-container-max-widths();
}

.setting-select-item {
  display: block;
  text-decoration: none;
  color: inherit;
  border-top: 1px solid $color__border-device-select;

  h3 {
    font-size: 1.1rem;
    margin: 0;
    font-weight: 500;
  }

  h6 {
    font-size: .75rem;
  }

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.setting-arrow {
  text-align: right;
}

.setting-icon .icon {
  width: 38px;
  height: 38px;
  //background-color: $color__brand-main;
  display: block;
  //border-radius: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;

  &.profile {
    background-image: url("../../img/user-icon.svg");
    background-size: 25px;
  }

  &.notification {
    background-image: url("../../img/notification-icon.svg");
    background-size: 20px;
  }

  &.place {
    background-image: url("../../img/place-icon.svg");
    background-size: 28px;
  }

  &.remote {
    background-image: url("../../img/remote-icon.svg");
    background-size: 28px;
  }
  
  &.storefront {
    background-image: url("../../img/storefront-icon.svg");
    background-size: 28px;
  }  
  
  &.download {
    background-image: url("../../img/download-icon.svg");
    background-size: 20px;
  }

  &.users {
    background-image: url("../../img/group-icon.svg");
    background-size: 20px;
    background-color: $color__device-tv-main; 
  }

  &.dining {
    background-color: $color__btn-primary;
    background-size: contain;
    background-position: center 5px;
    background-image: url("../../img/fork.svg");
  }

  &.mini-bar {
    background-image: url("../../img/bottle.svg");
    background-size: 55%;
    background-position: center 7px;
  }
}

.setting-status {
  font-weight: normal;
  text-align: right;

  .temp {
    color: #c0c5cf;
    display: block;
    clear: both;
  }

  .state {
    text-transform: uppercase;
    font-size: 0.5rem;
    padding: 0.25rem 0.6rem;
    letter-spacing: 0.03em;
    background-color: $color__background-device-state;
    line-height: 1;
    border-radius: 7px;
    display: inline-block;
    text-align: center;

    &.cooling {
      background: #26a6ef;
    }

    &.heating {
      background-color: #e5476c;
    }
  }
}

.setting--title {
  font-size: 1rem;
  margin-bottom: 0;
  font-weight: 500;
  color: $color__main-light-grey;

}

.setting--description {
   display: flex;
   align-items: center;
   
}
