@keyframes fadedBlur {
  from {
    filter: blur(0px);
  }
  to {
    filter: blur(20px);
  }
}

.blurring {
  animation: fadedBlur 350ms;
  animation-fill-mode: forwards;
}

.removing-blur {
  animation: fadedBlur 350ms reverse;
  animation-fill-mode: forwards;
}

.blurred {
  filter: blur(20px);
}
