@import "variables/colors";
@import "variables/sizes";

$custom-range-thumb-width: 2rem;
$custom-range-track-bg: $color__device-lights-main;
$custom-range-track-border-radius: 0;
$custom-range-thumb-bg: white;
$custom-range-track-box-shadow: inset 0 0.25rem 0.25rem rgba(#000, 0.1);
$custom-range-thumb-active-bg: white;
$custom-range-track-height: 0.35rem;

@import "node_modules/bootstrap/scss/bootstrap";

@import "typography";

body {
  min-height: 100vh;
}

@import "utils/utils-master";

/* UI Components */
@import "components/components";

/* Home Screen */
@import "home";

/* Login Screen */
@import "login";

/* Room Service / Ordering */
@import "order-menu";

/* Concierge */
@import "concierge";

/* Profile */
@import "profile";
