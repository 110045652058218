$header-upper-height: 65px;
$header-lower-height: 49px;

@keyframes fadeElementIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.navigation-header {
  position: relative;
  height: $header-upper-height + $header-lower-height;
  z-index: 200;
  @include make-container();
  @include make-container-max-widths();

  &.no-lower {
    height: $header-upper-height;
  }

  .user-profile-wrap {
    justify-content: flex-end;
  }

  select {
    color: white;
    text-align: right;
    text-align-last: right;
    font-size: 0.8375rem;
    background: none;
    border: none;
    padding: 0 5px 0 0;
    margin-right: 5px;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    border: none;
    outline: none;
    font-weight: 500;

    &.source-select {
      text-align: left;
      text-align-last: left;
    }

    &.room-select {
      direction: rtl;
      float: right;
      option {
        direction: ltr;
        background-color: $color__main-grey;
      }
    }
  }
}

.go-back-button {
  width: 19px;
  height: 32px;
  background-color: transparent;
  border: none;
  background-image: url("../../img/back-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  padding: 0;
  margin-bottom: 2px;
  &:focus {
    outline: none;
  }
}

.control-wrapper {
  position: relative;
}

.nav-header.access-control h1 {
  font-size: 1rem;
}

.header-upper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  background-color: $color__main-grey;
  padding: 1em 15px;
  height: $header-upper-height;

  @include make-container();
  @include make-container-max-widths();
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);

  .exited &,
  .hide-header-upper & {
    div:not(.back) {
      display: none !important;
    }
  }

  .title {
    text-align: center;
    margin-bottom: 0;

    h1 {
      font-size: 1.125rem;
      font-weight: 500;
      margin: 0;
    }

    .long-title & {
      flex: 2;
    }
  }

  & > div {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    animation: fadeElementIn 250ms linear;

    &.rooms {
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }

    &.back {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }

    &.search {
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }
  }
}

.header-lower {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: absolute;
  top: $header-upper-height;
  left: 0;
  width: 100%;
  padding: 0 15px 1em;
  height: $header-lower-height;

  .title h1,
  .source-select {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 0;
    min-width: 53px;
    font-weight: 500;
    line-height: 1;
  }
}
