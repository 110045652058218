@-webkit-keyframes swipeIn {
  from {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes swipeIn {
  from {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes swipeOut {
  from {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes swipeOut {
  from {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.my-profile {
  @include make-container();
  @include make-container-max-widths();
}

.user-profile-form {
  max-width: 100%;
  padding: 0;
}

.profile-wrapper {
  @include media-breakpoint-down("sm") {
    padding: 0;
  }
}

.profile-wrapper h1,
.user-management h1 {
  font-size: 1.25rem;
  margin-bottom: 0;
}

.user-table {
  table thead th {
    border: 0;
  }

  table td {
    border: none;
  }
}

.account-users {
  overflow: hidden;
  max-width: 100%;
}

a.edit-profile {
  color: $color__brand-main;
}

.user {
  padding: 1em 15px;
  border-top: 1px solid $color__border-device-select;
  position: relative;
  h5 {
    font-size: 1em;
    margin-bottom: 0;
  }
  p.email {
    margin-bottom: 0;
    font-size: 0.9em;
  }
  .user-image img {
    max-width: 40px;
    margin-right: 0.75em;
    height: auto;
  }

  .btn {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0;
    padding: 0 1em;

    &:focus {
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &.swipe-enter-active,
    &.swipe-enter-done {
      -webkit-animation: swipeIn 0.2s linear;
      animation: swipeIn 0.2s linear;
    }

    &.swipe-exit-active {
      -webkit-animation: swipeOut 0.2s linear;
      animation: swipeOut 0.2s linear;
    }
  }
}

.edit-profile-cancel-button,
.edit-profile-edit-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1;
}

.edit-profile-submit-button {
  position: fixed;
  bottom: 30px;
  right: 85px;
  z-index: 1;
}

.add-user,
.edit-profile-button {
  button {
    color: white;
    border-radius: 5%;
    background-color: $color__brand-main;
    width: 150px;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: none;
    -webkit-box-shadow: 10px 10px 40px -12px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 40px -12px rgba(0, 0, 0, 0.75);
    font-weight: 500;

    &:focus {
      outline: none;
    }
    span {
      line-height: 1;
      font-size: 1.6em;
      text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
    }
  }
}

.forgot-password h3 {
  font-size: 1.25rem;
}

.password-reset h1 {
  font-size: 1.25rem;
}

.logout-container {
  text-align: center;
  justify-content: flex-end !important;

  button {
    padding: 0;
    background: none;
    color: $color__brand-main;
    border: none;
  }
}

.custom-ui {
  text-align: center;
  width: auto;
  padding: 40px;
  background: #1d1f22;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.custom-ui > h1 {
  margin-top: 0;
}

.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}

.replace-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.replace-image-container-active {
  background-image: url("../img/camera.svg");
  background-size: 12% 12%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 50%;
  height: 50%;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.replace-image-active {
  opacity: .5;
}

button.btn-replace-image {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  appearance: none;
  background: rgba(0, 0, 0, 0.5);
  border-color: #ced4da;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  padding-left: 0;
  margin: 0;
  width: 100%;
  padding: 1em;
  transition: all 0.2s;
}

.user-profile-photo {
  max-width: 50%;
  border-radius: 50%;
}

.edit-container {
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 0 3px;
}

.btn-save,
.btn-cancel {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: $color__brand-main;
}



