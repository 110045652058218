$animatonDuration: 250ms;
$halfAnimationDuration: $animatonDuration / 2;

.route {
  min-height: 100vw;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
  transform: scale(0.8);
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms transform 300ms;
}
