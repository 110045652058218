.toggle-switch,
.toggle-switch .toggle-switch--knob {
  transition: all 0.2s ease-in;
}

.toggle-switch {
  height: 32px;
  width: 64px;
  display: inline-block;
  background-color: #424858;
  margin: 2px 2px 5px 2px;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;

  .toggle-switch--knob {
    width: 26px;
    height: 26px;
    margin-left: 4px;
    display: inline-block;
    background-color: $color__main-light-grey;
    box-shadow: 0px 3px 5px 0px rgba(145, 97, 87, 0.33);
    border-radius: 100%;
    z-index: 50;
  }

  &.active {
    border: solid 1px transparent;
    background-color: #e5476c;

    .toggle-switch--knob {
      margin-left: 33px;
    }
  }
}
